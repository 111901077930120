<template>
    <div>
        <ValidationObserver ref="form1" slim>
            <form @submit.prevent="register" novalidate="novalidate">

                <b-alert variant="danger" show v-if="error">
                    {{ error }}
                </b-alert>

                <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Geslacht</label>
                        <b-form-radio-group v-model="form.gender" :options="genderOptions" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
                
                <b-row>
                    <b-col cols="12" sm="6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Voornaam</label>
                                <b-form-input v-model="form.firstname" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="6">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Achternaam</label>
                                <b-form-input v-model="form.lastname" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                </b-row>

                <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Bedrijfsnaam</label>
                        <b-form-input v-model="form.company_name" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <b-row>
                    <b-col cols="12" sm="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Postcode</label>
                                <b-form-input v-model="form.zipcode" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="4">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Huisnummer</label>
                                <b-form-input v-model="form.house_number" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                    <b-col cols="12" sm="4">
                        <ValidationProvider rules="" v-slot="{ errors }">
                            <div class="mb-3">
                                <label>Toevoeging</label>
                                <b-form-input v-model="form.house_number_suffix" :class="errors.length > 0 ? 'is-invalid' : null" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                    </b-col>
                </b-row>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Straatnaam</label>
                        <b-form-input v-model="form.street" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Plaats</label>
                        <b-form-input v-model="form.city" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
                <h5 class="mt-4">Inloggegevens</h5>
                <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">E-mail</label>
                        <b-form-input v-model="form.username" placeholder="mijn@account.nl" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
                <ValidationProvider rules="required|min:8" v-slot="{ errors }">
                    <div class="mb-3">
                        <label class="required">Wachtwoord</label>
                        <b-form-input type="password" v-model="form.password" placeholder="●●●●●●●●●" :class="errors.length > 0 ? 'is-invalid' : null" />
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <div class="text-center mb-3">
                    <b-button variant="primary" @click="register()" size="lg" block>
                        Registreren
                    </b-button>
                </div>

                <div class="mb-3 text-center">
                    <small>
                        <p>Deze site wordt beschermd door reCAPTCHA. Het <a href="https://policies.google.com/privacy" target="_blank" class="text-primary">privacybeleid</a> en de <a href="https://policies.google.com/terms" target="_blank" class="text-primary">voorwaarden</a> van Google zijn van toepassing.</p>
                        <p>Als je op 'Registreren' klikt, accepteer je onze <a href="https://www.tixgo.nl/algemene-voorwaarden/" target="_blank" class="text-primary">algemene voorwaarden</a> en bevestig je dat je achttien (18) jaar of ouder bent of toestemming hebt van je ouders of verzorgers.</p>
                    </small>
                </div>

            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default ({
        components: {
            ValidationObserver,
            ValidationProvider,
        },
        props: {
            url: {
                type: [Boolean, String],
                default: '/account/verify',
            },
            login: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                error: '',
                form: {
                    firstname: '',
                    lastname: '',
                    username: '',
                    password: '',
                    gender: '',
                    zipcode: '',
                },
                genderOptions: [
                    { value: 'male', text: 'Dhr.' },
                    { value: 'female', text: 'Mevr.' },
                ]
            }
        },
        methods: {
            async register() {

                this.error = '';

                let check = await this.$refs['form1'].validate();

                if (!check) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    return;
                }

                this.$axios.post("https://api.tixgo.nl/accounts/create", this.form)
                    .then( response => {
                        if (response.data.status == 'success') {
                            if (this.login) {
                                this.$axios.post("https://api.tixgo.nl/accounts/login", this.form)
                                    .then( response => {
                                        if (response.data.status == 'success') {
                                            localStorage.setItem('type', response.data.session.type);
                                            localStorage.setItem('token', response.data.session.token);
                                            localStorage.setItem('role', response.data.session.role);
                                            
                                            this.$axios.defaults.headers.common = {
                                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                            };
                                            
                                            if (this.url) {
                                                this.$router.push(this.url);
                                            } else {
                                                window.location.reload();
                                            }
                                        }
                                    }
                                );
                            } else {
                                if (this.url) {
                                    this.$router.push(this.url);
                                } else {
                                    window.location.reload();
                                }
                            }
                        } else {
                            this.error = 'Deze gebruikersnaam is reeds bezet.';
                        }
                    }
                );
            }
        }
    });
</script>